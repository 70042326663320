<script>
export default {
	metaInfo: {
		title: '¿Cómo comprar?',
	},
	trackPageView: true,
}
</script>

<template>
	<v-container class="py-10">
		<v-row>
			<v-col cols="12" class="d-flex align-center border-bottom py-10">
				<span class="rounded-pill font-weight-bold font-6 pa-3 border mr-5">1</span>
				<div class="mb-5">
					<div class="font-weight-bold font-5 mb-3">HACÉ TU COMPRA</div>
					<div>
						Seleccioná los artículos que más te gusten. Elegí el modelo y cantidad para agregarlo/s a tu
						carrito.
					</div>
				</div>
			</v-col>
			<v-col cols="12" class="d-flex align-center border-bottom py-10">
				<span class="rounded-pill font-weight-bold font-6 pa-3 border mr-5">2</span>
				<div class="mb-5">
					<div class="font-weight-bold font-5 mb-3">FINALIZÁ EL PEDIDO</div>
					<div>
						Una vez que hayas terminado de elegir los artículos, Ingresa a tu CARRITO, clickeando en el
						ícono de la bolsa en el menú superior. Seleccioná el botón FINALIZAR COMPRA y deberás
						completar tus datos de facturación y envío.
					</div>
				</div>
			</v-col>
			<v-col cols="12" class="d-flex align-center border-bottom py-10">
				<span class="rounded-pill font-weight-bold font-6 pa-3 border mr-5">3</span>
				<div class="mb-5">
					<div class="font-weight-bold font-5 mb-3">PAGÁ</div>
					<div>
						Podés elegir pagar tu compra de 2 formas:
						<ol>
							<li>
								Por Mercadopago (Opción: “Paga con el medio de pago que prefieras”) totalmente online
								y seguro.
							</li>
							<li>Abonando mediante depósito o transferencia bancaria.</li>
						</ol>
					</div>
				</div>
			</v-col>
			<v-col cols="12" class="d-flex align-center border-bottom py-10">
				<span class="rounded-pill font-weight-bold font-6 pa-3 border mr-5">4</span>
				<div class="mb-5">
					<div class="font-weight-bold font-5 mb-3">RECIBÍ TU PEDIDO</div>
					<div>
						Una vez finalizados los pasos anteriores recibirás un mail de confirmación con el detalle de
						tu compra. Nos contactaremos para confirmar el despacho del mismo, en el caso de Capital y
						GBA con la fecha y horario estimado de entrega y en el caso del resto del país con el código
						de seguimiento de Correo Argentino para que puedas seguir tu pedido!
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped>
.border {
	border: 3px solid var(--v-primary-base);
	color: var(--v-primary-base);
}
.border-bottom {
	border-bottom: 1px solid #ccc;
}
</style>
