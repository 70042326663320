<script>
import img from './assets/57243.jpg'

export default {
	metaInfo: {
		title: 'Preguntas frecuentes',
	},
	trackPageView: true,
	data() {
		return {
			img,
			items: [
				{
					title: 'CAMBIOS Y DEVOLUCIONES',
					content:
						'Los cambios se realizarán por única vez dentro de los 10 dias posteriores a la compra con previo aviso sujeto a disponibilidad. Esto aplica a productos de la temporada vigente que no estuviesen en promo o en SALE. Condiciones: Los Productos deberán encontrarse en el mismo estado en que fueron remitidos, sin haber sido utilizados, y con el packaging, embalaje y etiquetas originales en buen estado. El costo de envío en caso de cambio o devolucion estará a cargo del comprador. Podrás realizar un cambio por el mismo producto en otro color, o bien por cualquier otro producto de la marca, sujeto a disponibilidad. En caso de haber una diferencia a favor del cliente, se podrá usar como parte de pago de otro producto o bien quedará a cuenta. En ningún caso, se realizarán devoluciones en efectivo.',
				},
				{
					title: '¿QUÉ HAGO SI ME LLEGA UN ARTÍCULO DEFECTUOSO O INCORRECTO?',
					content:
						'Esto es inusual pero en el caso de que, excepcionalmente, hayas recibido un producto defectuoso o incorrecto, el proceso es el mismo que si fuese un cambio con la diferencia de que Basualdo se hará cargo de los gastos de envío. No obstante, si tenés alguna duda, por favor comunicate con nosotros a través de cualquiera de nuestros canales de Atención al Cliente. Estamos a tu disposición.',
				},
				{
					title: 'ENVÍOS',
					content:
						'En Basualdo queremos que tengas una excelente experiencia de compra, por eso te ofrecemos varias opciones de envío para que elijas la que mejor se adecúe a tu comodidad. Podrás elegir entre el envío a domicilio por correo o en caso de estar en CABA y GBA podrás recibirlo por moto mensajería. En envíos por moto mensajería la demora es de 24 a 72 hrs hábiles, en envíos por correo al resto del país puede demorar de 3 a 10 días hábiles.',
				},
				{
					title: '¿CÓMO PUEDO HACER EL SEGUIMIENTO DE LA ENTREGA POR CORREO HASTA MI DOMICILIO?',
					content:
						'A partir del momento en que el servicio de correos se hace cargo del paquete, podrás conocer en todo momento el estado del mismo gracias al código de seguimiento del pedido que te enviaremos por e-mail al despacharlo.',
				},
				{
					title: '¿CUÁL ES EL COSTO DE ENVÍO?',
					content:
						'Al hacer tu pedido cuando cargues el domicilio de facturación y entrega, se calculará automáticamente el costo del envío, el cual pagarás al momento de hacer tu compra.',
				},
				{
					title: '¿PUEDO MODIFICAR O CANCELAR MI PEDIDO?',
					content:
						'Una vez tu pedido haya salido a distribución, no podremos cancelar ni modificar el mismo. Una vez que recibas el pedido, vas a poder tramitar el cambio que desees.',
				},
				{
					title: '¿ES SEGURO COMPRAR ONLINE?',
					content:
						'Todas las compras realizadas en Basualdo e realizan mediante tarjeta de crédito o débito. Basualdo.com posee un sistema de cobranza que es 100% seguro. Todas las compras con tarjeta de crédito y débito son procesadas mediante MercadoPago, y así lo verás reflejado en tu resumen de cuentas.',
				},
				{
					title: '¿PUEDO RESERVAR UN PRODUCTO?',
					content:
						'Los productos no podrán ser reservados mediante la web, los cobros serán por la totalidad del mismo.',
				},
				{
					title: '¿QUÉ FORMAS DE PAGO OFRECEMOS?',
					content:
						'En Basualdo podrás abonar tus compras online mediante tarjeta de crédito y/o débito incluidas en la plataforma de Mercado Pago.',
				},
				{
					title: '¿TENGO QUE PAGAR UN ENVÍO POR CADA PRODUCTO?',
					content:
						'Pagarás el envio por el pedido que realices, es decir, si realizas una compra de varios productos en un mismo pedido pagarás el envio por toda la compra, en caso de cargar los productos en pedidos diferentes pagarás por cada pedido que hagas.',
				},
			],
		}
	},
}
</script>

<template>
	<div>
		<Media :src="img" height="10rem" cover>
			<div class="font-weight-bold font-5 d-flex align-center justify-center white--text h100 contrast">
				Preguntas frecuentes
			</div>
		</Media>
		<v-container class="pt-16 mb-16" v-if="items">
			<v-row>
				<v-col cols="12">
					<v-expansion-panels focusable flat>
						<v-expansion-panel class="pb-3">
							<v-expansion-panel-header class="font-2 font-weight-bold">
								¿CÓMO COMPRAR?
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="pt-4">
									Para saber cómo operar en nuestro sitio web ingresa a
									<SafeLink
										to="/comprar"
										class="font-weight-bold primary--text"
										style="text-decoration: underline"
									>
										Cómo Comprar
									</SafeLink>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel v-for="(item, i) in items" :key="i" class="pb-3">
							<v-expansion-panel-header class="font-2 font-weight-bold">
								{{ item.title }}
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="pt-4">
									{{ item.content }}
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<style scoped>
.contrast {
	background: rgba(0, 0, 0, 0.5);
}
</style>
